import {
  faArrowsSpin,
  faBook,
  faFileText,
  faPenAlt,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

type Props = {
  callbacks: {
    uploadFont: (e: React.ChangeEvent<HTMLInputElement>) => void;
    fontNameState: [string, React.Dispatch<React.SetStateAction<string>>]
  };
};

const Navigation = ({ callbacks: {uploadFont, fontNameState} }: Props) => {
  return (
    <div className="flex w-full justify-between rounded-md bg-[#07202D] p-4">
      <div className="flex gap-4">
        <Button
          fileUpload
          text="Upload font"
          onFileChange={uploadFont}
          iconOnLeft={<FontAwesomeIcon icon={faPenAlt} />}
        />
      </div>
      <div className="flex gap-4 items-center">
        <div className="input-label">New font name</div>
        <input className='input-text' onChange={(e) => fontNameState[1](e.target.value)} value={fontNameState[0]}   />
      </div>
    </div>
  );
};

export default Navigation;
